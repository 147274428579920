<template>
  <div class="list-page">
    <div>
      <el-button
        style="margin-bottom: 20px"
        type="primary"
        size="small"
        @click="handleAddBrand"
        >新增
      </el-button>
    </div>
    <div>
      <SourceScreen
        :jumptypes="jumptypes"
        :positions="positions"
        :filterForm="filterForm"
        :uploadFilter="ok"
      >
      </SourceScreen>
    </div>
    <list-tabs
      v-model="filterForm.tab"
      :tabs="tabs"
      :isLoading="formLoading"
      @tab-click="handleChangeTab"
    >
    </list-tabs>
    <div class="list-content" v-loading="formLoading">
      <div class="list-btn">
        <el-button
          v-if="filterForm.tab === 'deleted'"
          type="success"
          size="small"
          @click="handleReduct"
          >还原
        </el-button>
        <el-button type="danger" size="small" @click="handleDel"
          >删除
        </el-button>
      </div>
      <el-table
        style="width: 100%"
        class="thead-light"
        stripe
        :data="list"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="排序">
          <template slot-scope="{ row }">
            <!--          <el-input v-model="scope.row.sort" type="text" @keyup.enter.native="$event.target.blur"-->
            <!--            @blur="updateIsShow($event, scope.row.id, scope.$index)"></el-input>-->
            <PopoverInputEdit
              input-type="number"
              :max="255"
              :validator="validator"
              @confirm="updateIsShow(row)"
              v-model="row.sort"
            />
            <!--          <span v-else>{{ scope.row.sort | placeholder }}</span>-->
          </template>
        </el-table-column>

        <el-table-column
            show-overflow-tooltip
            min-width="300"
            label="推广图"
            prop="image"
        >
          <template slot-scope="scope">
            <list-image is-image :src="fixImageUrl(scope.row.image, 900)" :width="250" :height="100" />
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          label="广告名称"
          min-width="100"
          show-overflow-tooltip
        ></el-table-column>
        <!-- <el-table-column prop="position" label="位置" min-width="100"></el-table-column>
        <el-table-column prop="jump_type_text" label="类型" min-width="70"></el-table-column> -->

        <el-table-column
          show-overflow-tooltip
          min-width="100"
          label="推广标题"
          prop="cover_title"
        >
          <template slot-scope="scope">
            {{ scope.row.cover_title ? scope.row.cover_title : '--' }}
          </template>
        </el-table-column>
        <el-table-column min-width="100" label="广告分类" prop="category_name">
          <template slot-scope="scope">
            {{ scope.row.category_name ? scope.row.category_name : '--' }}
          </template>
        </el-table-column>
        <el-table-column
          min-width="100"
          label="广告类型"
          prop="banner_type_text"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="time_text"
          label="推广时段"
          min-width="300"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          min-width="200"
          label="投放范围"
          prop="scope_type_text"
        >
        </el-table-column>
        <el-table-column
          prop="view_record_sum"
          label="点击次数"
          min-width="100"
          :sortable="true"
        >
          <template slot-scope="scope">
            {{ scope.row.view_record_sum ? scope.row.view_record_sum : 0 }}
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="100">
          <template slot-scope="scope">
            <!--                       :type="scope.row.status_text === '未开始' ? 'warning':-->
            <!--                       (scope.row.status_text === '进行中' ? 'success':-->
            <!--                       (scope.row.status_text === '已结束' ? 'info':'danger'))"-->
            <list-status-text :text="scope.row.status_text"></list-status-text>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="create_time"
          label="创建时间"
          min-width="200"
        >
        </el-table-column>
        <!-- <el-table-column label="显示">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_show" @change="isOpen($event, scope.row.id)" :true-label="1"
              :false-label="0"></el-checkbox>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="130" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="filterForm.tab != 'deleted'"
              style="color: #3576ff"
              size="small"
              type="text"
              @click="EditRow(scope.row)"
              >编辑
            </el-button>
            <el-button
              style="color: #3576ff"
              size="small"
              type="text"
              @click="deleteRow(scope.row)"
              >删除
            </el-button>
            <el-button
              v-if="filterForm.tab === 'deleted'"
              style="color: #3576ff"
              size="small"
              type="text"
              @click="Reduct(scope.row)"
              >还原
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="pageData.total"
        :page="pageData.current_page"
        :change="changPage"
      />
    </div>
  </div>
</template>

<script>
import {
  indexList,
  positionType,
  updateSort,
  isDisplay,
  softDelete,
  deLs,
  putBack,
} from '../api/advert-list'
import Pagination from '@/base/components/Default/Pagination'
import PopoverInputEdit from '@/base/components/Popover/PopoverInputEdit'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import SourceScreen from '../components/SourceScreen'
import { Delete } from '../../brand-admin/api/brand-list'
import ListStatusText from '../../../base/components/List/ListStatusText'
import ListImage from '../../../base/components/List/ListImage'

export default {
  data() {
    return {
      formLoading: false,
      list: [],
      positions: [],
      selectArr: [], //勾选的数组id
      jumptypes: [
        {
          id: 0,
          name: '文章',
        },
        {
          id: 1,
          name: '活动',
        },
        {
          id: 2,
          name: '话题',
        },
        {
          id: 3,
          name: '专题',
        },
        {
          id: 4,
          name: '链接',
        },
        {
          id: 5,
          name: '小程序页面',
        },
      ],
      tabs: [
        { label: '全部', name: 'normal' },
        { label: '草稿箱', name: 'draft' },
        // { label: "进行中", name: "running" },
        // { label: "已结束", name: "finished" },
        // { label: "未开始", name: "not_running" },
        { label: '回收站', name: 'deleted' },
      ],
      /* 行内编辑弹窗表单验证 */
      validator: {
        type: 'integer',
        min: 0,
        max: 255,
        message: '请输入0~255的整数',
        trigger: 'blur',
      },
      filterForm: {
        tab: 'normal',
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        create_start_time: -1, //开始时间
        create_end_time: -1, //结束时间
        banner_type: -1,
        scope_type: '',
        category_id: '',
        page_size: 15,
        keyword: '',
      },
      filters: {
        normal: null,
        running: null,
        finished: null,
        not_running: null,
        deleted: null,
      },
      pageData: {},
    }
  },
  components: {
    ListImage,
    ListStatusText,
    DatePeriodPicker,
    SourceScreen,
    PopoverInputEdit,
    Pagination,
  },
  created() {
    this.getPidList(this.filterForm)
  },
  methods: {
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
        })
      }
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (
          this.filterForm.tab === 'normal' ||
          this.filterForm.tab === 'draft'
        ) {
          this.$msgbox
            .confirm('确定要将这些数据移入回收站吗？', '提示', {
              type: 'warning',
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr })
            })
            .catch((err) => {})
        } else {
          this.$msgbox
            .confirm('确定要将这些数据永久删除吗？', '提示', {
              type: 'warning',
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr })
            })
            .catch((err) => {})
        }
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab === 'normal' || this.filterForm.tab === 'draft') {
        this.$msgbox
          .confirm('确定要将此数据移入回收站吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.handleDelete({ id: row.id })
          })
          .catch((err) => {})
      } else {
        this.$msgbox
          .confirm('确定要将此数据永久删除吗？', '提示', {
            type: 'error',
          })
          .then((res) => {
            this.handleDelete({ id: row.id })
          })
          .catch((err) => {})
      }
    },
    // 数据删除
    handleDelete(id) {
      this.formLoading = true
      if (this.filterForm.tab === 'normal') {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg)
            this.reflashList()
          })
          .catch((err) => {
            this.formLoading = false
          })
      } else {
        deLs(id)
          .then((res) => {
            this.$message.success(res.msg)
            this.reflashList()
          })
          .catch((err) => {
            this.formLoading = false
          })
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm('确定要将这些数据还原吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr })
          })
          .catch((err) => {})
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm('确定要将此数据还原吗？', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.Reduct({ id: row.id })
        })
        .catch((err) => {})
    },
    // 还原数据
    Reduct(id) {
      this.formLoading = true
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg)
          this.reflashList()
        })
        .catch((err) => {
          this.formLoading = false
        })
    },
    // 点击编辑
    EditRow(row) {
      this.$router.push({
        name: 'edit-advert',
        params: { id: row.id },
      })
    },
    //新增
    handleAddBrand() {
      this.$router.push({ name: 'AddAdvert' })
    },
    isOpen(e, id) {
      this.formLoading = true
      isDisplay({ id: id })
        .then((res) => {
          this.$message.success(res.msg)
          this.reflashList()
        })
        .catch((err) => {
          this.formLoading = false
        })
    },
    updateIsShow(row) {
      this.formLoading = true
      updateSort({ id: row.id, sort: row.sort })
        .then((res) => {
          this.$message.success(res.msg)
          this.reflashList()
        })
        .catch((err) => {
          this.formLoading = false
        })
    },
    //修改tab栏
    handleChangeTab(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name]
      } else {
        this.filterForm = {
          tab: e.name,
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          create_start_time: -1, //开始时间
          create_end_time: -1, //结束时间
          banner_type: '',
          scope_type: '',
          category_id: '',
          page_size: this.pageData.per_page,
          keyword: '',
        }
      }
      this.getPidList(this.filterForm)
    },
    getPidList(data) {
      this.list = []
      this.pageData = {}
      this.formLoading = true
      indexList(data)
        .then((res) => {
          this.list = res.data.data
          this.pageData = res.data
          this.filters[res.data.tab] = {
            ...this.filterForm,
            page_size: res.data.per_page,
          }
          this.formLoading = false
        })
        .catch((err) => {})
    },
    // 筛选回调
    ok(e) {
      // const pageData = {};
      this.filterForm = { ...e, page: 1 }
      this.getPidList(this.filterForm)
    },
    //分页
    changPage(data) {
      this.filterForm.page_size = data.page_size
      this.getPidList({ ...this.filterForm, page: data.page })
    },
    // 刷新列表
    reflashList() {
      this.getPidList({
        page: this.pageData.current_page,
        ...this.filterForm,
      })
    },
  },
}
</script>
